import React from 'react';
import './ChooseUs.css';

const WhyChooseUsSection = ({ title, heading, description, features, image }) => {
  return (
    <section className="why-choose-us-section">
      <div className="why-choose-us-content">
        <h4 className="why-choose-us-title">{title}</h4>
        <h2 className="why-choose-us-heading">{heading}</h2>
        <p className="why-choose-us-description">{description}</p>
        <div className="why-choose-us-features">
          {features.map((feature, index) => (
            <div key={index} className="feature-box">
              {feature}
            </div>
          ))}
        </div>
      </div>
      <div className="why-choose-us-image">
        <img src={image} alt="Why Choose Us" />
      </div>
    </section>
  );
};

export default WhyChooseUsSection;
