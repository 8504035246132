// src/pages/Home.js
import React from 'react';
import Navbar from '../components/molecules/Navbar';
import HeroSection from '../components/organisms/HeroSection';
import ServicesSection from '../components/organisms/ServicesSection';
import TestimonialsSection from '../components/organisms/TestimonialsSection';
import Footer from './Footer';
import About from './About';
import Services from './Services';
import ChooseUsPage from './ChooseUs';
import Testimonials from './Testimonials';
import ContactUsSection from './Contact';
import WorkFlow from './WorkFlow';

const Home = () => {
  const slides = [
    {
      title: "One Platform",
      subtitle: "Unlimited Potential",
      description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
      buttonText: "Get Started",
      image: "https://stpracticeoncloud.blob.core.windows.net/quantumcatalyst/slick01.png",
    },
    {
      title: "One Platform",
      subtitle: "Unlimited Potential",
      description: "Discover how AI can transform your business with ease.",
      buttonText: "Learn More",
      image: "https://stpracticeoncloud.blob.core.windows.net/quantumcatalyst/slider2.png",
    },
    {
      title: "One Platform",
      subtitle: "Unlimited Potential",
      description: "Discover how AI can transform your business with ease.",
      buttonText: "Learn More",
      image: "https://stpracticeoncloud.blob.core.windows.net/quantumcatalyst/slider3.png",
    }
  ];
  return (
    <div className="home-page">
      <HeroSection slides={slides} />
      <About/>
      <Services />
      <ChooseUsPage/>
      <WorkFlow/>
      <Testimonials />
      <ContactUsSection/>
    </div>
  );
};

export default Home;
