import React from 'react';
import './Contact.css';

const ContactUsSection = () => {
  return (
    <section className="contact-us-section">
      <h2 className="contact-us-heading">Free to start, and affordable as you grow</h2>
      <p className="contact-us-subheading">Contact Us and we'll get in touch</p>
      <div className="contact-us-buttons">
        <button className="get-started-btn1">Get Started</button>
        <button className="contact-us-btn">Contact Us</button>
      </div>
    </section>
  );
};

export default ContactUsSection;
