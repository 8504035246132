// src/App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './components/molecules/Navbar';
import Home from './pages/Home';
import About from './pages/About';
import Services from './pages/Services';
import Contact from './pages/Contact';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Footer from './pages/Footer';


const App = () => {
  const logo = {
    url: 'https://stpracticeoncloud.blob.core.windows.net/quantumcatalyst/QCLogo.png',
    alt: 'Quantum Catalyst Logo',
  };

  const menuItems = [
    { name: 'Home', path: '/' },
    { name: 'About', path: '/about' },
    { name: 'Services', path: '/services' },
    { name: 'Contact Us', path: '/contact' },
  ];
  return (
    <Router>
      <Navbar
        logo={logo}
        menuItems={menuItems}
        signinText="Sign In"
        buttonText="Get Started"
      />   
         <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/home" element={<Home />} />

        <Route path="/about" element={<About />} />
        <Route path="/services" element={<Services />} />
        <Route path="/contact" element={<Contact />} />
      </Routes>
      <Footer />

    </Router>
  );
};

export default App;
