import React from 'react';
import ServicesSection from '../components/organisms/ServicesSection';

const servicesData = [
  {
    title: 'Artificial Intelligence',
    description: 'It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages.',
    icon: '/path-to-ai-icon.png',
    image: 'https://stpracticeoncloud.blob.core.windows.net/quantumcatalyst/service1.png'
  },
  {
    title: 'Machine Learning',
    description: 'It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages.',
    icon: '/path-to-ml-icon.png',
    image: 'https://stpracticeoncloud.blob.core.windows.net/quantumcatalyst/service2.png'
  },
  {
    title: 'Cloud Datacenter',
    description: 'It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages.',
    icon: '/path-to-cloud-icon.png',
    image: 'https://stpracticeoncloud.blob.core.windows.net/quantumcatalyst/service3.png'
  },
  {
    title: 'Data Science',
    description: 'It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages.',
    icon: '/path-to-ds-icon.png',
    image: 'https://stpracticeoncloud.blob.core.windows.net/quantumcatalyst/service4.png'
  },
  {
    title: 'Cyber Security',
    description: 'It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages.',
    icon: '/path-to-cs-icon.png',
    image: 'https://stpracticeoncloud.blob.core.windows.net/quantumcatalyst/service5.png'
  },
  {
    title: 'DevOps',
    description: 'It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages.',
    icon: '/path-to-devops-icon.png',
    image: 'https://stpracticeoncloud.blob.core.windows.net/quantumcatalyst/service6.png'
  }
];

const HomePage = () => {
  return (
    <div>
      <ServicesSection services={servicesData} />
    </div>
  );
};

export default HomePage;
