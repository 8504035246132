import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { motion } from 'framer-motion';
import Button from '../atoms/Button';
import './Navbar.css';

const Navbar = ({ logo, menuItems, signinText, buttonText }) => {
  const [drawerOpen, setDrawerOpen] = useState(false); // State to control drawer

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  return (
    <>
      <motion.nav
        className="navbar"
        initial={{ y: -100 }}
        animate={{ y: 0 }}
        transition={{ duration: 0.5, ease: 'easeInOut' }}
      >
        {/* Logo Section */}
        <div className="navbar-logo">
          <NavLink to="/" exact>
            <img src={logo.url} alt={logo.alt} className="navbar-logo-image" />
          </NavLink>
        </div>

        {/* Hamburger Menu for Mobile */}
        <div className="navbar-hamburger" onClick={toggleDrawer}>
          <span></span>
          <span></span>
          <span></span>
        </div>

        {/* Dynamic Navigation Links */}
        <div className={`navbar-links ${drawerOpen ? 'open' : ''}`}>
          {menuItems.map((item, index) => (
            <li key={index}>
              <NavLink
                to={item.path}
                className={({ isActive }) => (isActive ? 'active-link' : '')}
                end
              >
                {item.name}
              </NavLink>
            </li>
          ))}
        </div>

        {/* Sign In and Get Started Buttons */}
        <div className="navbar-buttons">
          <NavLink to="/signin" className="navbar-signin">
            {signinText}
          </NavLink>
          <Button text={buttonText} className="navbar-btn get-started-btn" />
        </div>

        {/* Drawer Background Overlay */}
        {drawerOpen && <div className="drawer-overlay" onClick={toggleDrawer}></div>}
      </motion.nav>
    </>
  );
};

export default Navbar;
