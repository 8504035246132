import React from 'react';
import './WorkFlowSection.css';

const WorkflowSection = ({ workflowData }) => {
  return (
    <section className="workflow-section">
      <div className="workflow-image">
        <img src={workflowData.image} alt="Workflow" />
      </div>
      <div className="workflow-content">
        <h4 className="workflow-subtitle">{workflowData.subtitle}</h4>
        <h2 className="workflow-title">{workflowData.title}</h2>
        <p className="workflow-description">{workflowData.description}</p>
        <div className="workflow-steps">
          {workflowData.steps.map((step, index) => (
            <div key={index} className="workflow-step">
              <div className="workflow-icon">
              <img
                src={step.icon}
                className="testimonial-image"
              />              </div>
              <div className="workflow-step-content">
                <h5>{step.title}</h5>
                <p>{step.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default WorkflowSection;
