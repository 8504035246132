import React from 'react';
import Slider from 'react-slick';
import './HeroSection.css';

const HeroSection = ({ slides }) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 4000,
    arrows: false, // No arrows, using dots instead
  };

  return (
    <section className="hero-section">
  
       
      <Slider {...settings}>

        {slides.map((slide, index) => (
          <div className='slider'>
  <div
            key={index}
            className="hero-slide"
            style={{              backgroundImage: `url(${slide.image})`, // Set the image as the background
            }}
          >
            <div className="hero-content">
              <h1 className="hero-title">
                {slide.title} <br />
                <span>{slide.subtitle}</span>
              </h1>
              <p className="hero-description">{slide.description}</p>
              <button className="hero-button">{slide.buttonText}</button>
            </div>
          </div>
          </div>

        
        ))}

      </Slider>
    </section>
  );
};

export default HeroSection;
