import React from 'react';
import './ServicesSection.css';

const ServicesSection = ({ services }) => {
  return (
    <section className="services-section">
      <h3 className="services-subtitle">Our Services</h3>
      <h2 className="services-title">We Provide Best Advanced Services</h2>
      <div className="services-container">
        {services.map((service, index) => (
          <div key={index} className="service-card" style={{ backgroundImage: `url(${service.image})` }}>
            {/* <img src={service.icon} alt={`${service.title} Icon`} className="service-icon" /> */}
            <h4 className="service-title">{service.title}</h4>
            <p className="service-description">{service.description}</p>
          </div>
        ))}
      </div>
    </section>
  );
};

export default ServicesSection;
