import React from 'react';
import WhyChooseUsSection from '../components/organisms/ChooseUsSection';

const whyChooseUsData = {
  title: 'Why Choose Us',
  heading: 'Trusted World Widely',
  description:
    'It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop.',
  features: [
    '1000+ Trusted Clients',
    '30+ Dedicated Teams',
    'Outcome Focused',
    'Web Application Expert'
  ],
  image: 'https://stpracticeoncloud.blob.core.windows.net/quantumcatalyst/workflow.png'
};

const ChooseUsPage = () => {
  return (
    <div>
      <WhyChooseUsSection
        title={whyChooseUsData.title}
        heading={whyChooseUsData.heading}
        description={whyChooseUsData.description}
        features={whyChooseUsData.features}
        image={whyChooseUsData.image}
      />
    </div>
  );
};

export default ChooseUsPage;
