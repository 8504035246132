import React from 'react';
import WorkflowSection from '../components/organisms/WorkFlowSection';

const workflowData = {
  image: 'https://stpracticeoncloud.blob.core.windows.net/quantumcatalyst/client.png',
  subtitle: 'Our Work Flow',
  title: 'We Are Truly Able To Help Our Clients Live Happier',
  description: 'It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop.',
  steps: [
    {
      icon: 'https://stpracticeoncloud.blob.core.windows.net/quantumcatalyst/wf1.png', // Font Awesome icon class
      title: 'Our Research',
      description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.'
    },
    {
      icon: 'https://stpracticeoncloud.blob.core.windows.net/quantumcatalyst/wf2.png',
      title: 'Data Collection',
      description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.'
    },
    {
      icon: 'https://stpracticeoncloud.blob.core.windows.net/quantumcatalyst/wf3.png',
      title: 'Targeting',
      description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.'
    },
    {
      icon: 'https://stpracticeoncloud.blob.core.windows.net/quantumcatalyst/wf4.png',
      title: 'Solve Problem',
      description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.'
    }
  ]
};

const WorkFlow = () => {
  return (
    <div>
      <WorkflowSection workflowData={workflowData} />
    </div>
  );
};

export default WorkFlow;
