import React from 'react';
import FooterSection from '../components/molecules/FooterSection';

const brandData = {
  logo: 'https://stpracticeoncloud.blob.core.windows.net/quantumcatalyst/QCLogo.png',
  description: 'All the Lorem Ipsum generators on the Internet tend to repeat predefined as necessary, making this the first true generator on the Internet.',
  socialLinks: [
    { icon: 'https://stpracticeoncloud.blob.core.windows.net/quantumcatalyst/facebook.png', url: 'https://facebook.com' },
    { icon: 'https://stpracticeoncloud.blob.core.windows.net/quantumcatalyst/twitter.png', url: 'https://twitter.com' },
    { icon: 'https://stpracticeoncloud.blob.core.windows.net/quantumcatalyst/linkdien.png', url: 'https://linkedin.com' },
    { icon: 'https://stpracticeoncloud.blob.core.windows.net/quantumcatalyst/google.png', url: 'https://plus.google.com' }
  ]
};

const footerColumns = [
  {
    heading: 'Useful Links',
    links: [
      { text: 'About', url: '#about' },
      { text: 'Services', url: '#services' },
      { text: 'Price', url: '#price' },
      { text: 'Contact', url: '#contact' },
      { text: 'Testimonial', url: '#testimonial' }
    ]
  },
  {
    heading: 'Heading 1',
    links: [
      { text: 'About', url: '#about' },
      { text: 'Services', url: '#services' },
      { text: 'Price', url: '#price' },
      { text: 'Contact', url: '#contact' },
      { text: 'Testimonial', url: '#testimonial' }
    ]
  },
  {
    heading: 'Heading 2',
    links: [
      { text: 'About', url: '#about' },
      { text: 'Services', url: '#services' },
      { text: 'Price', url: '#price' },
      { text: 'Contact', url: '#contact' },
      { text: 'Testimonial', url: '#testimonial' }
    ]
  }
];

const Footer = () => {
  return (
    <div>
      <FooterSection brand={brandData} columns={footerColumns} />
    </div>
  );
};

export default Footer;
