import React from 'react';
import TestimonialSection from '../components/organisms/TestimonialsSection';

const testimonialData = [
  {
    image: 'https://stpracticeoncloud.blob.core.windows.net/quantumcatalyst/image.png',
    text: 'All the Lorem Ipsum generators on the Internet tend to repeat predefined as necessary, making this the first true generator on the Internet.',
    rating: 5,
    name: 'Metho K. Partho',
    location: 'Canada',
  },
  {
    image: 'https://stpracticeoncloud.blob.core.windows.net/quantumcatalyst/image.png',
    text: 'All the Lorem Ipsum generators on the Internet tend to repeat predefined as necessary, making this the first true generator on the Internet.',
    rating: 4,
    name: 'Metho K. Partho',
    location: 'Canada',
  },
  {
    image: 'https://stpracticeoncloud.blob.core.windows.net/quantumcatalyst/image.png',
    text: 'All the Lorem Ipsum generators on the Internet tend to repeat predefined as necessary, making this the first true generator on the Internet.',
    rating: 3,
    name: 'Jhon Doe',
    location: 'Canada',
  }
];

const Testimonials = () => {
  return (
    <div>
      <TestimonialSection testimonials={testimonialData} />
    </div>
  );
};

export default Testimonials;
