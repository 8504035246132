import React from 'react';
import './Footer.css';

const FooterSection = ({ brand, columns }) => {
  return (
    <footer className="footer-section">
      <div className="footer-top">
        <div className="footer-brand">
          <img src={brand?.logo} alt="Quantum Catalyst Logo" className="footer-logo" />
          <p className="footer-description">
            {brand.description}
          </p>
          <div className="footer-social-icons">
            {brand.socialLinks.map((social, index) => (
              <a key={index} href={social.url} target="_blank" rel="noreferrer">
                <img src={social.icon} />
              </a>
            ))}
          </div>
        </div>
        <div className="footer-links">
          {columns.map((column, index) => (
            <div key={index} className="footer-column">
              <h4>{column.heading}</h4>
              <ul>
                {column.links.map((link, linkIndex) => (
                  <li key={linkIndex}>
                    <a href={link.url}>{link.text}</a>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </div>
    </footer>
  );
};

export default FooterSection;
